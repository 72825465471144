.billModule {
  @apply fixed inset-0 invisible duration-500 ease-in-out opacity-0 pointer-events-none;
  @apply lg:col-start-12 lg:col-span-5 lg:row-start-1 lg:sticky lg:top-24 lg:inset-auto;
  transition-property: transform;
  transform: translateY(100%);
}

.billModule[data-open-mobile='false'] {
  @apply transition-[top] duration-200;
}

.billModule[data-open-mobile='true'] {
  @apply z-1299 visible opacity-100 pointer-events-auto;
  transform: translateY(0);
}

.mobileBarContainer {
  @apply sticky w-full col-container z-40 lg:hidden pointer-events-none;
  bottom: -1px;
}

@screen lg {
  .billModule,
  .billModule[data-open-mobile='true'] {
    @apply visible transition-none opacity-100 pointer-events-auto transform-none;
    height: fit-content;
  }
}
